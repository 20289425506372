export const MEASUREMENT_UNITS_TYPES = {
	LOAD_MEASUREMENT_UNITS:
		'MEASUREMENT_UNITS_TYPES.LOAD_MEASUREMENT_UNITS',
	CREATE_MEASUREMENT_UNIT:
		'MEASUREMENT_UNITS_TYPES.CREATE_MEASUREMENT_UNIT',
	EDIT_MEASUREMENT_UNIT:
		'MEASUREMENT_UNITS_TYPES.EDIT_MEASUREMENT_UNIT',
	DELETE_MEASUREMENT_UNIT:
		'MEASUREMENT_UNITS_TYPES.DELETE_MEASUREMENT_UNIT',
};
